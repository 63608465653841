<template>
  <b-card>
    <div class="d-flex align-items-center justify-content-between table-header">
      <h2 class="text-primary mb-1">
        {{ typeof $route.meta.pageTitle === 'function' ? $route.meta.pageTitle() : $route.meta.pageTitle }}
      </h2>

      <div class="d-flex align-items-center">
        <b-button class="ml-1" variant="dark" @click.prevent="$router.push({ name: 'user/list' })">
          <feather-icon icon="ListIcon" />
          <span class="d-none d-md-inline">Back To List</span>
        </b-button>
      </div>
    </div>

    <vue-form-json-schema v-model="model" :schema="schema" :ui-schema="uiSchema" />

    <b-row>
      <b-col md="12" :style="model.createMode === false ? 'display: none' : 'display: block'">
        <b-form-group>
          <label for="password-input">Password</label>
          <b-input-group>
            <b-form-input id="password-input" type="password" :type="passwordFieldType" v-model="model.login" required
              placeholder="****************"></b-form-input>
            <b-input-group-append>
              <b-button :variant="passwordFieldType === 'password' ? 'outline-dark' : 'outline-primary'"
                @click="togglePasswordVisibility('password')">
                <feather-icon :icon="passwordToggleIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-button class="d-flex align-items-center mt-50" variant="success" @click.prevent="handleSubmit">
      <feather-icon class="mr-50" icon="SaveIcon" />
      Save
    </b-button>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import uiSchema from './schema/FormCreateEditUiSchema.json'
import schema from './schema/FormCreateEditSchema.json'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    ToastificationContent,
    Cleave,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      model: {
        username: '',
        login: '',
        createMode: true,
      },
      options: {
        creditCard: {
          creditCard: true,
          blocks: [4, 4, 4],
        },
      },
      idUser: 0,
      schema,
      uiSchema,
      data: {},
      passwordFieldType: 'password',
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon';
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.model.createMode = false;
      this.getDetailData()
    }
  },
  methods: {
    formatPhoneNumber() {
      let numericValue = this.model.hp.replace(/[^\d\+\-\(\)]/g, '');
      numericValue = numericValue.replace(/(\d{4})[\s-]*(\d{4})[\s-]*(\d{3})/, '$1-$2-$3');
      this.model.hp = numericValue;
    },

    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/.*[0-9]/.test(char)) return true;
      else e.preventDefault();
    },

    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },

    handleSubmit() {
      if (this.$route.params.id) {
        this.handleEdit()
      } else {
        this.handleCreate()
      }
    },

    handleCreate() {
      if (!this.validateForm()) {
        return
      }

      const params = {
        username: this.model.username,
        login: this.model.login
      }

      this.$http.post('/api/user/register', params).then(res => {
        if (!res.data.status) {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'SlashIcon',
              variant: 'danger',
              text: res.data.message,
            },
          });
          return
        }

        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'CheckIcon',
            variant: 'success',
            text: '👋Berhasil menyimpan data',
          },
        });

        setTimeout(() => {
          this.$router.push('/user/list')
        }, 1000)
      }).catch(e => {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal menyimpan data' + e,
          },
        });
      })
    },

    validateForm() {
      if (this.model.username === '' || this.model.username === null) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Silakan mengisi username',
          },
        });
        return false;
      }
      if (!/^\S+$/.test(this.model.username)) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Username tidak boleh pakai spasi',
          },
        });
        return false;
      }
      if (!this.model.username || this.model.username.length >= 20) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'UserName tidak boleh melebihi dari 20 digit angka',
          },
        });
        return false;
      }
      if (!this.model.username || this.model.username.length < 6) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'UserName tidak boleh kurang dari 6 digit angka',
          },
        });
        return false;
      }

      if (!this.$route.params.id && (this.model.login === '' || this.model.login === null)) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Silakan mengisi password',
          },
        });
        return false
      }

      if (!/^(?=.*[A-Z])(?=.*[0-9!@#$%^&*()-_+=])[A-Za-z0-9!@#$%^&*()-_+= ]{6,12}$/.test(this.model.login)) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Password harus memiliki satu huruf kapital, mengandung angka dan simbol, dengan panjang 6-12 karakter',
          },
        });
        return false;
      }

      return true
    }
  }
}
</script>